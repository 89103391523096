<template>
    <div>支付验证....</div>
</template>
<script>
import {
    orderPayCheck
} from "@/api/index.js";
export default {
    data(){
        return{
            outerOrderId:''
        }
    },
    async created() {
    if (this.$route.query.x) {
      this.outerOrderId = this.$route.query.x
      await this.checkPay()
    }else{
        console.log('获取x失败')
    }
  },
    methods:{
        checkPay(){
            orderPayCheck(this.outerOrderId).then(res=>{
                console.log(res)
                if(res.data.success===true && res.data.data ===true){
                    this.$router.push({ path: `/orderComplete?x=${this.outerOrderId}` });
                }else{
                    this.$message({
                        message: "Please repurchase and place an order to pay",
                        type: "error",
                        duration: 5000
                    });
                    this.$router.replace({ path: "/checkOut", query: { payFail: 1 } });
                }
            })
        }
    }
}
</script>
<style>

</style>